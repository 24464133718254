import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    padding: "16px",
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "#f44336",
});

const StyledDialogContent = styled(DialogContent)({
  fontSize: "1.2rem",
  color: "#333",
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: "center",
  padding: "16px",
});

const StyledButton = styled(Button)({
  backgroundColor: "#f44336",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#ABCCF0",
  },
});

const ErrorDialog = ({ open, message, handleClose }) => {
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        {"Failed To Submit"}
        <IconButton onClick={handleClose} style={{ color: "#f44336" }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={handleClose} autoFocus>
          Close
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ErrorDialog;
