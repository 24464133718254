import { BrowserRouter } from "react-router-dom"; // BrowserRouter is now used inside Routes
import { Helmet } from "react-helmet";
import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import LandingPage from "./Components/LandingPage/LandingPage";
import Eapcet from "./Components/EAPCET/EapcetForm";
import Ecet from "./Components/ECET/EcetForm";
import Icet from "./Components/ICET/IcetForm";
import Pgcet from "./Components/PGCET/PgcetForm";
import Contact from "./Components/MainPage/Contact";
import RankChecker from "./Components/Ranks/CheckYourRank";
import FormSubmissionSuccess from "./Components/Success";
import Home from "./Components/MainPage/Home";
import About from "./Components/MainPage/About";
import MainLayout from "./Components/Admin/MainLayout";
import AdminHome from "./Components/Admin/AdminHome";
import AddAdmin from "./Components/Admin/AddAdmin";
import EmployeesListPage from "./Components/Admin/EmployeesListPage";
import StudentDetailsPage from "./Components/Admin/StudentDetailsPage";
import StudentList from "./Components/Admin/StudentList";
import Output from "./Components/Output/Output";
import AddEmployeeForm from "./Components/Admin/AddEmployeeForm";
import EapcetStudentsListing from "./Components/EAPCET/EapcetStudentsListing";
import EmployeeSuccessResponse from "./Components/SuccessResponse/EmployeeSuccessResponse";
import AdminLoginPage from "./Components/Admin/AdminLoginPage";
import AddEapcetDocuments from "./Components/Admin/AddEapcetDocuments";
import StudentDocumentsDetails from "./Components/EAPCET/StudentDocumentsDetails";
import ManagementForm from "./Components/Management/ManagementForm";

function App() {
  return (
    <div>
      <div className="App">
        <Helmet>
          <title>YSR Educational Services</title>
          <link rel="icon" href="%public%/favicon.ico" />
        </Helmet>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<h1>404 Error, Page Not Found</h1>} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/Admission-page" element={<LandingPage />} />
          <Route path="/EAPCET/student-form" element={<Eapcet />} />
          <Route path="/ECET/student-form" element={<Ecet />} />
          <Route path="/Icet/student-form" element={<Icet />} />
          <Route path="/PGCET/student-form" element={<Pgcet />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About-us" element={<About />} />
          <Route path="/Contact-us" element={<Contact />} />
          <Route path="/rank-checker" element={<RankChecker />} />
          <Route path="/success" element={<FormSubmissionSuccess />} />
          <Route path="/admin/login" element={<AdminLoginPage />} />
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/add-admin" element={<AddAdmin />} />
          <Route path="/admin/add-employee" element={<AddEmployeeForm />} />
          <Route
            path="/admin/employee-response"
            element={<EmployeeSuccessResponse />}
          />
          <Route path="/admin/employees-list" element={<EmployeesListPage />} />
          <Route
            path="/admin/student-details"
            element={<StudentDetailsPage />}
          />
          <Route
            path="/add-certificate-details/:id"
            element={<AddEapcetDocuments />}
          />
          <Route
            path="/admin/student-documents-details/:id"
            element={<StudentDocumentsDetails />}
          />
          <Route
            path="/admin/eapcet-students-listing"
            element={<EapcetStudentsListing />}
          />
          <Route
            path="/admin/icet-students-listing"
            element={<LandingPage />}
          />
          <Route
            path="/admin/pgecet-students-listing"
            element={<LandingPage />}
          />
          <Route
            path="/management-students-listing"
            element={<LandingPage />}
          />
          <Route
            path="/admin/allType-students-list"
            element={<StudentList />}
          />
          <Route
            path={"/admin/get-student-details-by-id/:studentId"}
            element={<Output />}
          />
          <Route path="/" element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/Management/student-form" element={<ManagementForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
