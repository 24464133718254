import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import api from "../../api";

const StudentDetailsPage = () => {
  const [studentId, setStudentId] = useState("");
  const [studentDetails, setStudentDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchStudentDetails = async () => {
    setLoading(true);
    try {
      const response = await api.getStudentsDetailsById(studentId);
      setStudentDetails(response.data);
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleFetchDetails = () => {
    if (studentId) {
      fetchStudentDetails();
    }
  };

  const handleDocumentsDetails = (id) => {
    navigate(`/add-certificate-details/${id}`);
  };

  const handleInputChange = (event) => {
    setStudentId(event.target.value);
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <ErrorOutlineIcon color="error" fontSize="large" />
          <Typography color="error" ml={1}>
            Error: {error}
          </Typography>
        </Box>
      </Container>
    );
  }

  if (!studentDetails) {
    return (
      <Container>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Enter Student ID"
            variant="outlined"
            value={studentId}
            onChange={handleInputChange}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchDetails}
          >
            Fetch Details
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Card>
        <CardHeader
          title="Student Details"
          titleTypographyProps={{ variant: "h4" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <InfoIcon color="primary" />
                <Typography variant="h6" ml={1}>
                  Basic Information
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Student ID:</strong> {studentDetails.id}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Name of Applicant:</strong>{" "}
                {studentDetails.nameOfApplicant}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Father's Name:</strong> {studentDetails.fatherName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Category:</strong> {studentDetails.category}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Phone Number:</strong> {studentDetails.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>With Reference Of:</strong>{" "}
                {studentDetails.withReferenceOf}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Box m={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDocumentsDetails(studentId)}
            fullWidth
          >
            Add Certificates
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

export default StudentDetailsPage;
