import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

const AddAdmin = () => {
  const [name, setName] = useState("Saddam ");
  const [email, setEmail] = useState("saddam@yopmail.com");
  const [password, setPassword] = useState("Freedom@10");
  const [adminType, setAdminType] = useState("subAdmin");
  const navigate = useNavigate();

  const handleRegistration = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://ysr-backend-1.onrender.com/api/v1/admin/create-admin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            password,
            adminType,
          }),
        }
      );
      if (response.ok) {
        navigate("/registaretion-success");
        console.log("Admin registration successful");
      } else {
        console.error("Admin registration failed please try again");
      }
    } catch (error) {
      console.error("Error registering admin:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Admin Registration
      </Typography>
      <form onSubmit={handleRegistration}>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            variant="outlined"
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            variant="outlined"
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            variant="outlined"
          />
        </Box>
        <Box mt={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="admin-type-label">Admin Type</InputLabel>
            <Select
              labelId="admin-type-label"
              value={adminType}
              onChange={(e) => setAdminType(e.target.value)}
              label="Admin Type"
              required
            >
              <MenuItem value="">
                <em>Select Admin Type</em>
              </MenuItem>
              <MenuItem value="superAdmin">Super Admin</MenuItem>
              <MenuItem value="subAdmin">Sub Admin</MenuItem>
              <MenuItem value="employeeAsAdmin">Employee as Admin</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="primary" type="submit">
            Register
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default AddAdmin;
