import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Input,
  FormHelperText
} from "@mui/material";

const AddEmployeeForm = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && value.length > 10) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      if (formData.phoneNumber.length !== 10) {
        throw new Error("Phone number must be 10 digits long");
      }

      const response = await api.addEmployee(formData);

      console.log("API Response:", response);

      if (response.success === true) {
        setMessage(response.message);
        navigate("/admin/employee-response");
      } else {
        console.log("Unexpected API Response:", response);
        const errorData = response.data || {};
        setMessage(errorData.message || "Unknown error");
      }
    } catch (error) {
      console.error("API Request Failed:", error);
      setMessage("Phone number must be 10 digits long");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Add Employee
        </Typography>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="name">
                  Name <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <FormHelperText>
                  Please enter the name of the employee
                </FormHelperText>
              </FormControl>
            </Box>

            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="phoneNumber">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Input
                  id="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
                {formData.phoneNumber.length !== 10 && (
                  <FormHelperText>
                    Phone number must be exactly 10 digits long
                  </FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button type="submit" variant="contained" color="primary">
                Add Employee
              </Button>
            </Box>
          </form>
        )}
        {message && (
          <Typography
            variant="body1"
            color={message.startsWith("Success") ? "success" : "error"}
            sx={{ mt: 2 }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default AddEmployeeForm;
