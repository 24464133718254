import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import Icon from "@mui/material/Icon";

const EapcetForm = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    nameOfApplicant: "",
    fatherName: "",
    dateOfBirth: "",
    addressOfCommunication: "",
    phoneNumber: "",
    phoneNumber1: "",
    aadharNo: "",
    category: "",
    date: "",
    requestType: "EAPCET",
    qualifyingDetails: {
      intermediate: {
        sscSchoolName: "",
        sscPassingYear: "",
        sscPercentage: "",
        hscSchoolName: "",
        hscPassingYear: "",
        hscPercentage: "",
        EAPCETHallTicketNo: "",
        EAPCETRank: ""
      },
      polytechnic: {
        polytechnicClgName: "",
        polytechnicPassingYear: "",
        polytechnicPercentage: "",
        ECETHallTicketNo: "",
        ECETRank: ""
      }
    },
    nameofInstution: "",
    courseName: [],
    courseLevel: "",
    withReferenceOf: "",
    reference: [
      {
        friendName: "",
        friendPhoneNumber: ""
      }
    ]
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNestedChange = (section, e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      qualifyingDetails: {
        ...prevData.qualifyingDetails,
        [section]: {
          ...prevData.qualifyingDetails[section],
          [name]: value
        }
      }
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedCourseName = checked
      ? [...formData.courseName, value]
      : formData.courseName.filter((level) => level !== value);
    setFormData((prevData) => ({ ...prevData, courseName: updatedCourseName }));
  };

  const handleAddFriend = () => {
    setFormData((prevData) => ({
      ...prevData,
      reference: [
        ...prevData.reference,
        { friendName: "", friendPhoneNumber: "" }
      ]
    }));
  };

  const handleDeleteFriend = (index) => {
    if (formData.reference.length > 1) {
      const updatedReferences = formData.reference.filter(
        (_, i) => i !== index
      );
      setFormData((prevData) => ({
        ...prevData,
        reference: updatedReferences
      }));
    }
  };

  const handleGetEmployees = async () => {
    try {
      setLoading(true);
      const response = await api.getAllEmployees();
      setEmployees(response.data.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetEmployees();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await api.eapcetFormSubmit(formData);
      if (res.data.success === true) {
        navigate("/success");
      } else {
        const errorData = await res.json();
        setMessage(
          "Error Submitting your Form: " + (errorData.message || res.statusText)
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage("Error Submitting your Form. Please try again later.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h3>Personal details</h3>
        <div>
          <div>Select Date:</div>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Name of Applicant:</div>
          <input
            type="text"
            name="nameOfApplicant"
            value={formData.nameOfApplicant}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Father's Name:</div>
          <input
            type="text"
            name="fatherName"
            value={formData.fatherName}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Date of Birth:</div>
          <input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Address of Communication:</div>
          <input
            type="text"
            name="addressOfCommunication"
            value={formData.addressOfCommunication}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Phone Number:</div>
          <input
            type="number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Alternate Number:</div>
          <input
            type="number"
            name="phoneNumber1"
            value={formData.phoneNumber1}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Aadhar Number:</div>
          <input
            type="text"
            name="aadharNo"
            value={formData.aadharNo}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Caste:</div>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            <option value="OC">OC</option>
            <option value="OC/EWS">OC/EWS</option>
            <option value="BCA">BCA</option>
            <option value="BCB">BCB</option>
            <option value="BCC">BCC</option>
            <option value="BCD">BCD</option>
            <option value="SC">SC</option>
            <option value="ST">ST</option>
          </select>
        </div>
        <div>
          <div>Institution Name:</div>
          <input
            type="text"
            name="nameofInstution"
            value={formData.nameofInstution}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>Select Course:</div>
          <select
            name="courseLevel"
            value={formData.courseLevel}
            onChange={handleChange}
          >
            <option value="">Select Course</option>
            <option value="B.TECH">B.TECH</option>
            <option value="B.PHARMACY">B.PHARMACY</option>
            <option value="MBBS">MBBS</option>
            <option value="MBA">MBA</option>
            <option value="MCA">MCA</option>
            <option value="M.TECH">M.TECH</option>
            <option value="M. PHARMACY">M. PHARMACY</option>
          </select>
        </div>
        {formData.courseLevel === "B.TECH" && (
          <>
            <div>
              <div>Select Highest Education:</div>
              <div>
                <input
                  type="radio"
                  name="highestEducation"
                  value="polytechnic"
                  checked={formData.highestEducation === "polytechnic"}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      highestEducation: e.target.value
                    }))
                  }
                />
                Polytechnic
              </div>
              <div>
                <input
                  type="radio"
                  name="highestEducation"
                  value="intermediate"
                  checked={formData.highestEducation === "intermediate"}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      highestEducation: e.target.value
                    }))
                  }
                />
                Intermediate
              </div>
            </div>
            {formData.highestEducation === "polytechnic" && (
              <div>
                <div>Polytechnic College Name:</div>
                <input
                  type="text"
                  name="polytechnicClgName"
                  value={
                    formData.qualifyingDetails.polytechnic.polytechnicClgName
                  }
                  onChange={(e) => handleNestedChange("polytechnic", e)}
                />
                <div>Polytechnic Passing Year:</div>
                <input
                  type="text"
                  name="polytechnicPassingYear"
                  value={
                    formData.qualifyingDetails.polytechnic
                      .polytechnicPassingYear
                  }
                  onChange={(e) => handleNestedChange("polytechnic", e)}
                />
                <div>Polytechnic Percentage:</div>
                <input
                  type="text"
                  name="polytechnicPercentage"
                  value={
                    formData.qualifyingDetails.polytechnic.polytechnicPercentage
                  }
                  onChange={(e) => handleNestedChange("polytechnic", e)}
                />
              </div>
            )}
            {formData.highestEducation === "intermediate" && (
              <div>
                <div>Inter School Name:</div>
                <input
                  type="text"
                  name="hscSchoolName"
                  value={formData.qualifyingDetails.intermediate.hscSchoolName}
                  onChange={(e) => handleNestedChange("intermediate", e)}
                />
                <div>Inter Passing Year:</div>
                <input
                  type="text"
                  name="hscPassingYear"
                  value={formData.qualifyingDetails.intermediate.hscPassingYear}
                  onChange={(e) => handleNestedChange("intermediate", e)}
                />
                <div>Inter Percentage:</div>
                <input
                  type="text"
                  name="hscPercentage"
                  value={formData.qualifyingDetails.intermediate.hscPercentage}
                  onChange={(e) => handleNestedChange("intermediate", e)}
                />
              </div>
            )}
            <div>
              <div>
                Select Course: <span style={{ color: "red" }}>*</span>
              </div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    name="courseName"
                    value="EEE"
                    checked={formData.courseName.includes("EEE")}
                    onChange={handleCheckboxChange}
                  />
                  EEE
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="courseName"
                    value="ECE"
                    checked={formData.courseName.includes("ECE")}
                    onChange={handleCheckboxChange}
                  />
                  ECE
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="courseName"
                    value="CSE"
                    checked={formData.courseName.includes("CSE")}
                    onChange={handleCheckboxChange}
                  />
                  CSE
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="courseName"
                    value="MECH"
                    checked={formData.courseName.includes("MECH")}
                    onChange={handleCheckboxChange}
                  />
                  MECH
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="courseName"
                    value="CIVIL"
                    checked={formData.courseName.includes("CIVIL")}
                    onChange={handleCheckboxChange}
                  />
                  CIVIL
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="courseName"
                    value="IT"
                    checked={formData.courseName.includes("IT")}
                    onChange={handleCheckboxChange}
                  />
                  IT
                </div>
              </div>
            </div>
          </>
        )}
        <div>
          <div>With Reference Of:</div>
          <select
            name="withReferenceOf"
            value={formData.withReferenceOf}
            onChange={handleChange}
          >
            <option value="">Select With Reference Of</option>
            {employees.map((employee) => (
              <option
                key={`${employee.name} (${employee.phoneNumber})`}
                value={`${employee.name} (${employee.phoneNumber})`}
              >
                {`${employee.name} (${employee.phoneNumber})`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div>Friends Details:</div>
          {formData.reference.map((reference, index) => (
            <div key={index}>
              <input
                type="text"
                name={`friendName${index}`}
                value={reference.friendName}
                onChange={(e) => {
                  const updatedReferences = [...formData.reference];
                  updatedReferences[index].friendName = e.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    reference: updatedReferences
                  }));
                }}
                placeholder="Enter Friend Name"
              />
              <input
                type="text"
                name={`friendPhoneNumber${index}`}
                value={reference.friendPhoneNumber}
                onChange={(e) => {
                  const updatedReferences = [...formData.reference];
                  updatedReferences[index].friendPhoneNumber = e.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    reference: updatedReferences
                  }));
                }}
                placeholder="Enter Friend Number"
              />
              {formData.reference.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleDeleteFriend(index)}
                  style={{ background: "none", border: "none" }}
                >
                  <Icon
                    baseClassName="fas"
                    className="fa-minus-circle"
                    color="error"
                  />
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddFriend}
            style={{ background: "none", border: "none" }}
          >
            <Icon
              baseClassName="fas"
              className="fa-plus-circle"
              color="primary"
            />
          </button>
        </div>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default EapcetForm;
