import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box
} from "@mui/material";
import CenteredCircularProgress from "../../helper/Loading";
import api from "../../api";

const Container = styled.div`
  max-width: 90%;
  margin: auto;
  margin-top: 50px;
`;

const EapcetStudentsListing = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleDocumentsDetails = (id) => {
    navigate(`/admin/student-documents-details/${id}`);
  };

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await api.getEapcetStudentsList();
        setStudents(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" color="error" gutterBottom>
          Error: {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Student List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Name of Applicant</TableCell>
              <TableCell>Father's Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>With Reference Of</TableCell>
              <TableCell align="center">View Documents</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <TableRow key={student.id}>
                <TableCell>{student.id}</TableCell>
                <TableCell>{student.date}</TableCell>
                <TableCell>{student.nameOfApplicant}</TableCell>
                <TableCell>{student.fatherName}</TableCell>
                <TableCell>{student.category}</TableCell>
                <TableCell>{student.phoneNumber}</TableCell>
                <TableCell>{student.withReferenceOf}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDocumentsDetails(student.id)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default EapcetStudentsListing;
