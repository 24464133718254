import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardContent, Typography } from "@mui/material";

const Walkins = () => {
  const [walkins, setWalkins] = useState(0);

  useEffect(() => {
    axios
      .get(
        "https://ysr-backend-1.onrender.com/api/v1/user/admin/allType-students-data/EAPCET"
      ) // Adjust the endpoint to your backend API
      .then((response) => {
        setWalkins(response.data.count);
      })
      .catch((error) => {
        console.error("There was an error fetching the walk-in data!", error);
      });
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Walk-ins
        </Typography>
        <Typography variant="h2" component="div">
          {walkins}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Walkins;
