import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import classes from "./Ecet.module.css";
import SchlLogo from "../Images/YSR logo.jpeg";
import "react-datepicker/dist/react-datepicker.module.css";
import styled from "styled-components";
import Navbar from "../MainPage/Navbar";
import api from "../../api";

const Container = styled.div`
  max-width: auto;
  margin: auto;
  padding: 30px;
  @media (max-width: 600px) {
    padding: 30px;
  }
`;

const NextButton = styled.button`
  width: 40%;
  margin-top: 5%;
  margin-left: 58%;
  margin-right: 2%;
  padding: 10px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #21a1f1;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const LabelA = styled.label`
  margin-bottom: 5px;
  display: flex;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background: #fafafa;
}
  `;

const Select = styled.select`
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: none;
  box-sizing: border-box;
`;

const CheckboxGroup = styled.div`
  display: flex;
  margin-left: 5%;
  flex-wrap: wrap;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  margin-top: 2%;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #21a1f1;
  }
`;

const StyledButton = styled.button`
  width: 40%;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 10px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #21a1f1;
  }
`;
const EcetForm = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedDate, setselectedDate] = useState(null);
  const [message, setMessage] = useState("");
  const [employees, setEmployees] = useState([""]);

  const [formData, setFormData] = useState({
    nameOfApplicant: "",
    fatherName: "",
    dateOfBirth: "",
    addressOfCommunication: "",
    phoneNumber: "",
    aadharNo: "",
    category: "",
    date: "",
    requestType: "ECET",
    qualifyingDetails: [
      {
        polytechnicClgName: "",
        polytechnicPassingYear: "",
        polytechnicPercentage: "",
        ECETHallTicketNo: "",
        ECETRank: "",
      },
    ],
    nameofInstution: "",
    courseLevels: [""],
    withReferenceOf: "",
    reference: [
      {
        friendName: "",
        friendPhoneNumber: "",
      },
    ],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNestedChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQualifyingDetails = [...formData.qualifyingDetails];
    updatedQualifyingDetails[index][name] = value;
    setFormData({
      ...formData,
      qualifyingDetails: updatedQualifyingDetails,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedCourseLevels = checked
      ? [...formData.courseLevels, value]
      : formData.courseLevels.filter((level) => level !== value);
    setFormData({
      ...formData,
      courseLevels: updatedCourseLevels,
    });
  };

  const handleAddFriend = () => {
    setFormData({
      ...formData,
      reference: [
        ...formData.reference,
        { friendName: "", friendPhoneNumber: "" },
      ],
    });
  };

  const handleDeleteFriend = (index) => {
    if (formData.reference.length > 1) {
      const updatedReferences = formData.reference.filter(
        (_, i) => i !== index
      );
      setFormData({
        ...formData,
        reference: updatedReferences,
      });
    }
  };
  const handleGetEmployees = async () => {
    try {
      const response = await api.getAllEmployees();
      console.log("API Response:", response.data);
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  useEffect(() => {
    handleGetEmployees();
  }, []);

  useEffect(() => {
    console.log("Employees:", employees);
  }, [employees]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await api.ecetFormSubmit(formData);
      if (res.data.success == true) {
        navigate("/success");
      } else {
        const errorData = await res.json();
        setMessage(
          "Error Subitting your Form: " + (errorData.message || res.statusText)
        );
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
    // try {
    //   const response = await fetch(
    //     "https://ysr-backend-1.onrender.com//api/v1/user/ecet-student-registration",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json"
    //       },
    //       body: JSON.stringify(formData)
    //     }
    //   );

    //   if (!response.ok) {
    //     throw new Error("Network response was not ok");
    //   }

    //   const responseData = await response.json();
    //   console.log("Form submitted successfully:", responseData);
    //   navigate("/success");
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    // }
  };

  return (
    <>
      <Navbar />
      <Container>
        <FormWrapper onSubmit={handleSubmit}>
          {page === 1 && (
            <FormControl style={{ paddingBottom: "0px", paddingTop: "70px" }}>
              {formData.qualifyingDetails.map((detail, index) => (
                <React.Fragment key={index}>
                  <h3>Qualifying Details for Engineering</h3>

                  <Label>Polytechnic College Name:</Label>
                  <Input
                    type="text"
                    name="polytechnicClgName"
                    value={detail.polytechnicClgName}
                    onChange={(e) => handleNestedChange(index, e)}
                  />

                  <Label>Polytechnic Passing Year:</Label>
                  <Input
                    type="number"
                    name="polytechnicPassingYear"
                    value={detail.polytechnicPassingYear}
                    onChange={(e) => handleNestedChange(index, e)}
                    min="1900"
                    max="2100"
                    step="1"
                  />

                  <Label>Polytechnic Percentage:</Label>
                  <Input
                    type="number"
                    name="polytechnicPercentage"
                    value={detail.polytechnicPercentage}
                    onChange={(e) => handleNestedChange(index, e)}
                    min="33.33"
                    max="100"
                    step="0.1"
                  />

                  <Label>EMCET Hall Ticket No:</Label>
                  <Input
                    type="text"
                    name="ECETHallTicketNo"
                    value={detail.ECETHallTicketNo}
                    onChange={(e) => handleNestedChange(index, e)}
                  />

                  <Label>EMCET Rank:</Label>
                  <Input
                    type="number"
                    name="ECETRank"
                    value={detail.ECETRank}
                    onChange={(e) => handleNestedChange(index, e)}
                  />
                </React.Fragment>
              ))}
              <NextButton type="button" onClick={() => setPage(2)}>
                Next
              </NextButton>
            </FormControl>
          )}
          {page === 2 && (
            <FormControl style={{ paddingBottom: "0px", paddingTop: "70px" }}>
              <h3>Personal details</h3>
              <Label>Select Date:</Label>
              <Input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              />

              <Label>Name of Applicant:</Label>
              <Input
                type="text"
                name="nameOfApplicant"
                value={formData.nameOfApplicant}
                onChange={handleChange}
              />

              <Label>Father's Name:</Label>
              <Input
                type="text"
                name="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
              />

              <Label>Date of Birth:</Label>
              <Input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
              />

              <Label>Address of Communication:</Label>
              <Input
                type="text"
                name="addressOfCommunication"
                value={formData.addressOfCommunication}
                onChange={handleChange}
              />

              <Label>Phone Number:</Label>
              <Input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />

              <Label>Aadhar Number:</Label>
              <Input
                type="text"
                name="aadharNo"
                value={formData.aadharNo}
                onChange={handleChange}
              />

              <Label>Category:</Label>
              <Select
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                <option value="OC">OC/EWS</option>
                <option value="BCA">BCA</option>
                <option value="BCB">BCB</option>
                <option value="BCC">BCC</option>
                <option value="BCD">BCD</option>
                <option value="BCE">BCE</option>
                <option value="SC">SC</option>
                <option value="ST">ST</option>
              </Select>

              <Label>Institution Name:</Label>
              <Input
                type="text"
                name="nameofInstution"
                value={formData.nameofInstution}
                onChange={handleChange}
              />

              <Label>Select Course:</Label>
              <CheckboxGroup>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="EEE"
                    checked={formData.courseLevels.includes("EEE")}
                    onChange={handleCheckboxChange}
                  />
                  EEE
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="ECE"
                    checked={formData.courseLevels.includes("ECE")}
                    onChange={handleCheckboxChange}
                  />
                  ECE
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="CSE"
                    checked={formData.courseLevels.includes("CSE")}
                    onChange={handleCheckboxChange}
                  />
                  CSE
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="CIVIL"
                    checked={formData.courseLevels.includes("CIVIL")}
                    onChange={handleCheckboxChange}
                  />
                  CIVIL
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="MECH"
                    checked={formData.courseLevels.includes("MECH")}
                    onChange={handleCheckboxChange}
                  />
                  MECH
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="IT"
                    checked={formData.courseLevels.includes("IT")}
                    onChange={handleCheckboxChange}
                  />
                  IT
                </LabelA>
              </CheckboxGroup>

              <Label>With Reference Of:</Label>
              <Select name="withReferenceOf">
                <option value="">Select With Reference Of</option>
                {employees &&
                  employees.data.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {`${employee.name} (${employee.phoneNumber})`}
                    </option>
                  ))}
              </Select>

              <Label>Friends Details:</Label>
              {formData.reference.map((reference, index) => (
                <tr key={index} className="friend-details">
                  <Input
                    type="text"
                    name={`friendName${index}`}
                    value={reference.friendName}
                    onChange={(e) => {
                      const updatedReferences = [...formData.reference];
                      updatedReferences[index].friendName = e.target.value;
                      setFormData({
                        ...formData,
                        reference: updatedReferences,
                      });
                    }}
                    placeholder="Enter Friend Name"
                  />
                  <Input
                    type="text"
                    name={`friendPhoneNumber${index}`}
                    value={reference.friendPhoneNumber}
                    onChange={(e) => {
                      const updatedReferences = [...formData.reference];
                      updatedReferences[index].friendPhoneNumber =
                        e.target.value;
                      setFormData({
                        ...formData,
                        reference: updatedReferences,
                      });
                    }}
                    placeholder="Enter Friend Number"
                  />
                  <Button
                    type="button"
                    className="remove-friend-button"
                    onClick={() => handleDeleteFriend(index)}
                  >
                    ✖
                  </Button>
                </tr>
              ))}
              <Button type="button" onClick={handleAddFriend}>
                + Add Friend
              </Button>

              <div className="navigation-buttons">
                <StyledButton
                  type="button"
                  className="previous-button"
                  onClick={() => setPage(1)}
                >
                  Previous
                </StyledButton>
                <StyledButton
                  type="button"
                  className="next-button"
                  onClick={() => setPage(3)}
                >
                  Preview
                </StyledButton>
              </div>
            </FormControl>
          )}
          {page === 3 && (
            <FormControl style={{ paddingBottom: "0px", paddingTop: "70px" }}>
              <div className={classes.formParentContainer}>
                <div style={{ borderBottom: "1px solid black" }}>
                  <div className={classes.headerContainer}>
                    <div className={classes.imageContainer}>
                      <div>
                        <img
                          src={SchlLogo}
                          style={{
                            maxWidth: "100px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <div>
                        <h1 className="heading">YSR EDUCATIONAL SERVICES</h1>
                        <h5 className="tagline">
                          "Enlightening the values of Education"
                        </h5>
                        <h6 className="address">
                          Flat No. 6-36/4, Room No. 4, 1st Floor, Opp.
                          Kukatpally Bus Depot, Moosapet, Hyderabad - 500018,
                          T.S., India.
                          <br /> Cell: 9959693939, 9133423939
                        </h6>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className={classes.formContainer}>
                  <p className={classes.preliminary}>
                    <u style={{ fontSize: "bold" }}>
                      PRELIMINARY APPLICATION FORM
                    </u>
                  </p>
                  <div className={classes.dateContainer}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginTop: "8px",
                      }}
                    >
                      Date:-
                    </p>
                    <div className={classes.dateText}>
                      <div onChange={(date) => setselectedDate(date)}>
                        {formData.date}
                      </div>
                    </div>
                  </div>
                  <div className={classes.formContents}>
                    <div className={classes.fieldContainer}>
                      <div style={{ width: "100%" }}>
                        <div className={classes.forMobileView}>
                          <p className={classes.formFieldText}>
                            {" "}
                            1.Name Of The Applicant
                          </p>{" "}
                          <span className={classes.colanMobile}>:</span>
                        </div>
                        <p className={classes.formFieldText}>
                          (IN Block Leeters As per SSC)
                        </p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.nameOfApplicant}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          {" "}
                          2.Father's Name
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.fatherName}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          {" "}
                          3.Date Of Birth
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.dateOfBirth}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          {" "}
                          4.Address For Communication
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className={classes.colan}>:</div>
                          <div
                            style={{ width: "100%" }}
                            className={classes.inputField}
                          >
                            {formData.addressOfCommunication}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          5.Tel No/Mobile No
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.phoneNumber}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>6.Aadhar No</p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.aadharNo}
                        </div>
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div style={{ width: "100%" }}>
                        <div className={classes.forMobileView}>
                          <p className={classes.formFieldText}>
                            7.Categorey Mangagement Quota/NRI
                          </p>{" "}
                          <span className={classes.colanMobile}>:</span>
                        </div>
                        <p className={classes.formFieldText}>
                          Foreign Students (If Applicable)
                        </p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className={classes.fieldContainer}>
                      <div className={classes.forMobileView}>
                        <p className={classes.formFieldText}>
                          8.OC/BC (A,B,C,D) SC/ST
                        </p>{" "}
                        <span className={classes.colanMobile}>:</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.colan}>:</div>
                        <div
                          style={{ width: "100%" }}
                          className={classes.inputField}
                          type="text"
                        >
                          {formData.category}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginTop: "8px" }}>
                      <p className={classes.formFieldText}>
                        9.Details of Qualifying Examination(10+2 degree)
                      </p>
                      <div style={{ overflowX: "auto" }}>
                        <table className={classes.tableContainer}>
                          <tr className={classes.tableRow}>
                            <th className={classes.tableColumn}>Course</th>
                            <th className={classes.tableColumn}>
                              School / University
                            </th>
                            <th className={classes.tableColumn}>
                              Month & year of passing
                            </th>
                            <th className={classes.tableColumn}>
                              Mark Obtained
                            </th>
                            <th className={classes.tableColumn}>
                              Percentage Of Marks
                            </th>
                            <th className={classes.tableColumn}>ECET H.NO</th>
                            <th className={classes.tableColumn}>ECET Rank</th>
                          </tr>
                          <tr className={classes.tableRow}>
                            <th className={classes.tableColumn}>Polytechnic</th>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {
                                  formData.qualifyingDetails[0]
                                    .polytechnicClgName
                                }
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {" "}
                                {
                                  formData.qualifyingDetails[0]
                                    .polytechnicPassingYear
                                }
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text" />
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {" "}
                                {
                                  formData.qualifyingDetails[0]
                                    .polytechnicPercentage
                                }
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {" "}
                                {formData.qualifyingDetails[0].ECETHallTicketNo}
                              </div>
                            </td>
                            <td className={classes.tableColumn}>
                              <div className={classes.tableInput} type="text">
                                {formData.qualifyingDetails[0].ECETRank}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div style={{ width: "100%", marginTop: "8px" }}>
                        <p className={classes.formFieldText}>
                          10.Name Of the Instution in which Admission is
                          Required:
                        </p>
                        <div style={{ width: "100%" }}>
                          <div
                            style={{ width: "100%" }}
                            className={classes.inputField}
                            type="text"
                          >
                            {formData.nameofInstution}
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "100%", marginTop: "8px" }}>
                        <p className={classes.formFieldText}>
                          11.Name Of the Course in which Admission is Required:
                        </p>

                        <div className={classes.formFields}>
                          <div>
                            <p className={classes.formFieldText}>{`B.TECH:`}</p>
                          </div>
                          <CheckboxGroup>
                            <LabelA
                              className={classes.checkBoxLable}
                              htmlFor="EEE"
                            >
                              <Input
                                type="checkbox"
                                id="EEE"
                                name="EEE"
                                value="EEE"
                                checked={formData.courseLevels.includes("EEE")}
                                onChange={handleCheckboxChange}
                              />{" "}
                              EEE
                            </LabelA>

                            <LabelA
                              className={classes.checkBoxLable}
                              htmlFor="ECE1"
                            >
                              {" "}
                              <Input
                                type="checkbox"
                                id="ECE1"
                                name="ECE"
                                value="ECE"
                                checked={formData.courseLevels.includes("ECE")}
                                onChange={handleCheckboxChange}
                              />{" "}
                              ECE
                            </LabelA>

                            <LabelA
                              className={classes.checkBoxLable}
                              htmlFor="CSE1"
                            >
                              <Input
                                type="checkbox"
                                id="CSE1"
                                name="CSE"
                                value="CSE"
                                checked={formData.courseLevels.includes("CSE")}
                                onChange={handleCheckboxChange}
                              />{" "}
                              CSE
                            </LabelA>

                            <LabelA
                              className={classes.checkBoxLable}
                              htmlFor="MECH"
                            >
                              <Input
                                type="checkbox"
                                id="MECH"
                                name="MECH"
                                value="MECH"
                                checked={formData.courseLevels.includes("MECH")}
                                onChange={handleCheckboxChange}
                              />
                              MECH
                            </LabelA>

                            <LabelA
                              className={classes.checkBoxLable}
                              htmlFor="CIVIL"
                            >
                              <Input
                                type="checkbox"
                                id="CIVIL"
                                name="CIVIL"
                                value="CIVIL"
                                checked={formData.courseLevels.includes(
                                  "CIVIL"
                                )}
                                onChange={handleCheckboxChange}
                              />{" "}
                              CIVIL
                            </LabelA>

                            <LabelA
                              className={classes.checkBoxLable}
                              htmlFor="IT"
                            >
                              {" "}
                              <Input
                                type="checkbox"
                                id="IT"
                                name="IT"
                                value="IT"
                                checked={formData.courseLevels.includes("IT")}
                                onChange={handleCheckboxChange}
                              />{" "}
                              IT
                            </LabelA>
                          </CheckboxGroup>
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "100%", marginTop: "8px" }}>
                      <p className={classes.formFieldText}>
                        {`13.with Referance of:- `}
                        {formData.withReferenceOf}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        className={classes.formFieldText}
                      >{`14.Referance:Mention the name of two persons known you with phone numbers of:`}</p>
                      <div>
                        {formData.reference.map((friend, index) => (
                          <span key={index}>
                            {index + 1}. {friend.friendName} -{" "}
                            {friend.friendPhoneNumber}{" "}
                          </span>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          width: "100%",
                          marginTop: "8px",
                        }}
                      >
                        <div>
                          <div
                            style={{ width: "100%" }}
                            className={classes.inputField}
                            type="text"
                          />
                          <p
                            className={classes.formFieldText}
                          >{`SIGNATURE OF THE PARENT`}</p>
                        </div>
                        <div>
                          <div
                            style={{ width: "100%" }}
                            className={classes.inputField}
                            type="text"
                          />
                          <p
                            className={classes.formFieldText}
                          >{`SIGNATURE OF THE STUDENT`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="navigation-buttons">
                <StyledButton
                  type="button"
                  className="previous-button"
                  onClick={() => setPage(2)}
                >
                  Previous
                </StyledButton>
                <StyledButton type="submit" className="submit-button">
                  Submit
                </StyledButton>
              </div>
            </FormControl>
          )}
        </FormWrapper>
      </Container>
    </>
  );
};

export default EcetForm;
